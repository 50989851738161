import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BnNgIdleService } from 'bn-ng-idle';
import { INITIAL_MODAL_STATE, Modal } from './shared/models/dialog.model';
import { AuthService } from './shared/services/auth.service';
import { SharedStateService } from './shared/services/shared-state.service';
import { StorageService } from './shared/services/storage.service';
import { errorResponse, settingsResponse } from './shared/models/user.model';
import { environment } from 'src/environments/environment';
import { UploadService } from './uploadfiles/upload.service';
import { LoginData } from './shared/models/login.model';
import { timer, Subscription } from 'rxjs';
import { constantStrings } from './shared/helpers/constants';
import { forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showSessionTimeOutModal: boolean;
  public sessionTimeOutModel: Modal = INITIAL_MODAL_STATE;
  public sessionTimeOutLocalize: any;
  isInfoPopUpSelected;
  showInfoPopUp: boolean;
  sessionUploadErrorLocalize: any;
  userActions: any;
  idleTime: number = 0;
  errorDetails: errorResponse;
  userInfo: LoginData;
  actionActivityDetails: settingsResponse;
  uploadProgress: any;
  auditAction: any;
  idelTimeOut: boolean = false;
  timeElapsed: number = 0; 
  userActivity: boolean = false;
  private timerSubscription: Subscription;
  extraTimerToUploadFile: number;
  auditLogActivityAPI: string = environment.baseUrl + '/imageupload/auditlog';
  actionOutcome: any = [];
  forkcallInitiated: boolean = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private translate: TranslateService,
    private bnIdle: BnNgIdleService,
    private sharedState: SharedStateService,
    private storageService: StorageService,
    private http: HttpClient,
    private uploadService: UploadService
  ) {
  }

  @HostListener('window:mousemove')
  @HostListener('window:click')
  @HostListener('window:keydown')
  handleUserActivity() {
    this.userActivity = true;
  } 
 

  ngOnInit(): void {
    //Reading Idletime ssmInfo after getting clpsession
    if(this.authService.getItem('clpSession')) this.ssmInfo();
    //Recieving loggedIn user details from stateservice 
    this.sharedState.loginStateData$.subscribe(response => {
      this.userInfo = response; 
    });
    //Recieving auditaction messages from stateservice
    this.sharedState.userActionStateData$.subscribe(response => {
      this.auditAction = response;
    });
    this.translate.setDefaultLang('en');
    this.translate.get(['sessionTimeOutModal'])
      .subscribe((data) => {
        this.sessionTimeOutLocalize = data.sessionTimeOutModal
      });

    this.translate.get(['uploadErrorModel'])
      .subscribe((data) => {
        this.sessionUploadErrorLocalize = data.uploadErrorModel
      });

      this.sharedState.uploadProgressStateData$.subscribe(response => {
        this.uploadProgress= response;
      })

    this.translate.get(['UserActions'])
      .subscribe((data) => {
        this.userActions = data;
        this.sharedState.userActionDetails(this.userActions);
      });

    this.sharedState.uploadStateData$.subscribe(response => {
      this.isInfoPopUpSelected = response.isInfoPopUp;
      if (this.isInfoPopUpSelected) {
        this.showInfoPopUp = true;
        this.sessionTimeOutModel = {
          display: 'block',
          header: "",
          content: "",
          okButton: "",
          cancelButton: '',
          submitFunction: () => { this.closeInfoPopupDialog(); },
          closeFunction: () => { this.closeInfoPopupDialog(); }
        };
      }
    })
    this.sharedState.ssmIdleTimeData$.subscribe(response => {
      if (response && response.ssmKey == environment.ssmParams.IDLETIME) {
        // this.ssmIdleTimeoutVal = +response.ssmVal;
        this.extraTimerToUploadFile = constantStrings.uploadTimeLimit - (+response.ssmVal);
        this.idleTime = +response.ssmVal * 60;
        if (this.idleTime != 0) {
          this.checkInactivity();
        }
      }
    })
  }

  /**
    * @description to get the SSM parameter values
    * @memberof component
  */
  public ssmInfo() {
    this.authService.getSSMValue(environment.ssmParams.IDLETIME);
  }

  // below function will watch the application idle time, after 15 min it will clear the sesstion and redirect to login page  
  public checkInactivity() {
    this.bnIdle.startWatching(this.idleTime).subscribe((res) => {
      if (res && (!this.router.url.includes('/signin') && !this.router.url.includes('/signup'))) {
        this.idelTimeOut = true;
        this.userActivity = false;
        this.auditLogActivity();
      }
    })
  }


  /**
   * @description To close the modal dailog.
   * @returns sets the modal in initial state.
   * @memberof AppComponent
   */
  public closeSessionTimeOutDialog() {
    this.showSessionTimeOutModal = false;
    this.sessionTimeOutModel = INITIAL_MODAL_STATE;
    this.storageService.clearAll();
    this.authService.signOut();
  }

  /**
   * @description To close the Info modal dailog.
   * @returns sets the modal in initial state.
   * @memberof AppComponent
  */
  public closeInfoPopupDialog() {
    this.sessionTimeOutModel = INITIAL_MODAL_STATE;
  }

  /**
   * (description): To call audit log activity function
   * (memberof) : component
  **/
  auditLogActivity() {
    if (!this.uploadProgress) {
      this.signOut();
    } else {
      if (this.extraTimerToUploadFile > 0) {
        if (this.timerSubscription && !this.timerSubscription.closed) {
          return;
        }
        const source = timer(0, 1000);
        const alreadyExistingBatchID = [];
        // Subscribe to the timer and store the subscription
        this.timerSubscription = source.subscribe(val => {
          this.timeElapsed = val;
          if (this.userActivity) {
            this.stopTimer();
          }

          // Stop the timer after extra timer reached
          const extraTimer = this.extraTimerToUploadFile * 60;
          if (this.timeElapsed > extraTimer || !this.uploadProgress) {
            if (this.uploadProgress && this.timeElapsed >= extraTimer) {
              let uploadFileStorage = this.authService.getItem('uploadFileStatus');
              for (let statusFile of uploadFileStorage) {
                if (statusFile.fileStatus == constantStrings.PROCESSING) {
                  const auditText = this.auditAction.UserActions.uploadFailedByIdleTimeout;
                  const auditId = statusFile.batchId;
                  if (!alreadyExistingBatchID.includes(statusFile.batchId)) {
                    alreadyExistingBatchID.push(statusFile.batchId)
                    const eventOutcome = 1;
                    this.actionOutcome.push( {
                      "userFacilityId": this.userInfo.userFacilityId,
                      "userName": this.userInfo.userName,
                      "action": auditText + '' + auditId,
                      "eventOutCome": eventOutcome
                    });
                  }
                }                  
              }
              if(this.actionOutcome.length > 0 && !this.forkcallInitiated){
                this.makeForkJoinPostCall()
              }
            } else {
              this.stopTimer();
              this.signOut();
            }
          }
        });
      } else {
        this.signOut();
      }
    }
  }

  makeForkJoinPostCall() {  
    this.forkcallInitiated = true;
    const apiCall = [];
    this.actionOutcome.forEach(postData => {
      apiCall.push( this.http.post(this.auditLogActivityAPI, postData));
    });

    forkJoin([...apiCall]).subscribe({
      next: result => {
        this.stopTimer();
        this.signOut();
      }, error: err => {
        this.stopTimer();
        this.signOut();
      }
    });
  }

  ngOnDestroy(): void {
    // Clean up the subscription if the component is destroyed
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  stopTimer(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  signOut() {
    let action = {
      "userFacilityId" : this.userInfo.userFacilityId,
      "userName" : this.userInfo.userName,
      "action" : this.auditAction.UserActions.logoutSuccess,
      "eventOutCome" : 1
    }
    this.uploadService.auditLogActivity(action).subscribe({
      next: result => {
        this.actionActivityDetails = result;
        this.storageService.clearAll();
        this.authService.signOut();
      }, error: err => {
        this.errorDetails = err;
        this.storageService.clearAll();
        this.authService.signOut();
      }
    });
  }
}
