import { SharedStateService } from '../shared/services/shared-state.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FilterModal, INITIAL_FILTERMODAL_STATE, INITIAL_MODAL_STATE, Modal, AuditlogModal, INITIAL_AUDITLOGMODAL_STATE, DownloadReadyModal, INITIAL_DOWNLOADREADYMODAL_STATE, ExpirationModal, INITIAL_EXPIRATIONMODAL_STATE } from '../shared/models/dialog.model';
import { SelectionModel } from '@angular/cdk/collections';
import { ROLE, statusCode } from '../shared/helpers/constants';
import { AuditlogService } from './auditlog.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../shared/services/auth.service';
import { AuditLogData, AuditLogExportData, GetAuditExportResponse, PostAuditExportResponse } from '../shared/models/user.model';
import { AuditlogfilterComponent } from './auditlogfilter/auditlogfilter.component';
import { constantStrings } from '../shared/helpers/constants';
import { DatePipe, formatDate } from '@angular/common';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-auditlog',
  templateUrl: './auditlog.component.html',
  styleUrls: ['./auditlog.component.scss'],
  providers: [DatePipe]
})
export class AuditlogComponent implements OnInit {
  isSurgeonTrue;
  isDisabled = false;
  searchTextOnPlaceHolder: string = 'Search';
  searchiconText: string = 'Search'
  dataSource = new MatTableDataSource();
  selection = new SelectionModel<any>(true, []);
  @Input() modal: Modal = INITIAL_MODAL_STATE;
  displayedColumns: string[] = ['select', 'actionDateTime', 'userName', 'action'];
  @ViewChild(AuditlogfilterComponent) FilterModel;
  public filterModal: FilterModal = INITIAL_FILTERMODAL_STATE;
  public compilationModal: AuditlogModal = INITIAL_AUDITLOGMODAL_STATE;
  public readyModal: DownloadReadyModal = INITIAL_DOWNLOADREADYMODAL_STATE;
  public expirationModal: ExpirationModal = INITIAL_EXPIRATIONMODAL_STATE;
  selectedFilters;
  public today: Date = new Date();
  filterFlag: boolean;
  compilationFlag: boolean;
  readyFlag: boolean;
  expirationFlag: boolean;
  auditlogFlag: boolean;
  userInfo: any;
  searchText: string;
  pagelimit: number;
  isdayChecked: boolean = false;
  selectedUploadData: any[];
  pageStart: number = 0;
  viewDetailsFlag: boolean = false;
  filterActive: boolean = false;
  exportSelectedFlag: boolean = false;
  selectedStartDate: Date;
  selectedEndDate: Date;
  startDateGradient: boolean = true;
  startEndGradient: boolean;
  auditlogObject: AuditLogData;
  auditlogExportObject: AuditLogExportData;
  errorRes: any;
  auditLogDetails: any;
  storeAuditData: any;
  auditCount: number;
  errorAuditLog: any;
  AuditLogRes: any;
  selectedUsername: any[];
  newList: any;
  userNameGradient: boolean;
  errUserDetails: any;
  actionActivityDetails: any;
  auditAction: any;
  auditText: string | number;
  auditId: string | number;
  actionDateTime: string;
  @Output() successId: EventEmitter<any> = new EventEmitter<any>();
  compileCompleteDate: string;
  postAuditExportDetails: PostAuditExportResponse;
  getAuditExportDetails: GetAuditExportResponse;
  downloadDone: boolean;
  subscription: Subscription;
  intervalTime: number;
  logfileUrl: string;
  readyValueFlag: boolean;
  userRole: string=ROLE.SNN_HIL_MANAGER;
  //receives the selected filter values sent from filedashboardfilter component
  receiveList($event) {
    this.selectedFilters = $event;
  }
  receiveReadyList($event) {
    this.readyValueFlag = $event;
  }
  constructor(private sharedState: SharedStateService,
    private _liveAnnouncer: LiveAnnouncer,
    private auditlogService: AuditlogService,
    private spinnerService: NgxSpinnerService,
    private datePipe: DatePipe,
    private authService: AuthService) {
    this.viewDetailsFlag = false;
    this.pagelimit = statusCode.PageLimit;
    this.authService.getSSMValue(environment.ssmParams.AUDITINTERVALTIME)
    this.sharedState.ssmIdleTimeData$.subscribe(response => {
      if (response && response.ssmKey == environment.ssmParams.AUDITINTERVALTIME) {
        this.intervalTime = +response.ssmVal * 60000;
      }
    })
    if (this.authService.getItem('userDetails')) {
      this.userInfo = this.authService.getItem('userDetails');
      this.auditlogObject = {
        userFacilityId: this.userInfo.userFacilityId,
        searchText: this.searchText,
        userName: [],
        fromActionDate: null,
        toActionDate: null,
        pageno: 0,
        limit: this.pagelimit,
        column: constantStrings.eventDate,
      }
      this.auditLogAPI(this.auditlogObject);
      this.auditlogExportObject = {
        actionIds: [],
        userFacilityId: this.userInfo?.userFacilityId,
      }
    }

  }

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    //To get user actions from json file
    this.sharedState.userActionStateData$.subscribe(response => {
      this.auditAction = response;
    });
    this.isSurgeonTrue = {
      isDisplaySelectionfacility: false,
      isDisplaySelectionSurgeon: false,
      isDisplaySelectionApplication: false,
      isUploadFailed: false,
      isHelpScreen: false
    }
    this.sharedState.setDashboardStateData(this.isSurgeonTrue);
  }

  ngAfterViewInit() {
    this.viewDetailsFlag = false;
    this.dataSource.sort = this.sort;
  }

  /**
 * (description): calling API to Fetch audit log data
 * (params): params
 * (memberof) : AuditLogComponent
 */
  public auditLogAPI(params): any {
    this.spinnerService.show();
    this.auditlogService.postFetchAuditLogsService(params).subscribe({
      next: result => {
        this.auditLogDetails = result;
        if (this.searchText) {
          this.auditLogActivity(constantStrings.SEARCH);
        }
        if (this.filterActive) {
          this.auditLogActivity(constantStrings.FILTER);
        } else {
          this.auditLogActivity(constantStrings.VIEW);
        }
        // Convert UTC to local timezone   
        if (result && result.auditLogResponseList) {
          for (let i = 0; i < result.auditLogResponseList.length; i++) {
            const auditlogData = result.auditLogResponseList[i];
            const utcDatetime = auditlogData.actionDateTime;
            const utcDate = new Date(utcDatetime);
            const actionDateSort = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000); // Convert to local timezone
            result.auditLogResponseList[i].actionDateTime = actionDateSort;
            //Converting data object to string format to display as per figma
            let fixedDatetime = actionDateSort.toISOString();
            result.auditLogResponseList[i]['actionDateDisplay'] = this.datePipe.transform(fixedDatetime, 'MM/dd/yyyy hh:mm:ss a');
          }
          if (this.auditlogObject.pageno == 0) {
            this.storeAuditData = result.auditLogResponseList;
          } else {
            this.storeAuditData = [...this.storeAuditData, ...result.auditLogResponseList]
          }
        }
        this.dataSource = new MatTableDataSource(this.storeAuditData);
        setTimeout(() => {
          this.dataSource.sort = this.sort;
        }, 100);
        if (this.auditLogDetails.auditLogResponseList?.length) {
          this.auditCount = this.auditLogDetails.auditLogResponseList?.length
        } else {
          this.auditCount = 0;
        }
        this.spinnerService.hide();
      }, error: err => {
        //If API fails setting the auditCount to 0 for displaying error message
        this.auditCount = 0;
        if (this.searchText) {
          this.auditLogActivity(constantStrings.SEARCH);
        }
        if (this.filterActive) {
          this.auditLogActivity(constantStrings.FILTER);
        } else {
          this.auditLogActivity(constantStrings.VIEW);
        }
        this.errorRes = err;
        this.spinnerService.hide();
      }
    });
  }

  /**
  * (description): method called display the filtered items
  * (parameter) : null
  * (memberof) : Component
  */
  filterActiveFlag() {
    if (this.selectedFilters?.userName?.length > 0 || this.selectedFilters?.startDate || this.selectedFilters?.endDate) {
      this.filterActive = true;
    } else {
      this.filterActive = false;
    }
  }
  /**
* (description): calling API to Export csv file
* (memberof) : Audit Log Component
*/
  public auditLogExportAPI(params): any {
    this.spinnerService.show();
    this.auditlogService.postExportAuditLogsService(params).subscribe({
      next: result => {
        this.auditLogActivity(constantStrings.AUDITEXPORT);
        const a = document.createElement("a");
        a.href = "data:text/csv," + result;
        let filename = "auditLogExport";
        a.setAttribute("download", filename + ".csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        this.spinnerService.hide();
      }, error: err => {
        this.auditLogActivity(constantStrings.AUDITEXPORT);
        this.errorAuditLog = err;
        this.spinnerService.hide();
      }
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }


  /**
  * (description): when 24hrs checkbox is clicked this function is called
  * (parameter) : null
  * (memberof) : AuditLogComponent
  */
  dayCheckChecked() {
    this.selectedUsername = [];
    this.selectedStartDate = null;
    this.selectedEndDate = null;
    if (this.FilterModel != undefined) {
      this.auditlogObject.fromActionDate = null;
      this.auditlogObject.toActionDate = null;
      this.auditlogObject.userName = [];
      this.FilterModel.clearAll();
    }
    this.isdayChecked = !this.isdayChecked;
    if (this.isdayChecked == true) {
      let twentyfourHours = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
      this.auditlogObject.fromActionDate = this.formatedDate(twentyfourHours, constantStrings.STARTDATE);
      this.auditlogObject.toActionDate = this.formatedDate(new Date(new Date().getTime()), constantStrings.ENDDATE);
      this.auditLogAPI(this.auditlogObject);
    }
    if (this.isdayChecked == false) {
      this.auditlogObject.fromActionDate = null;
      this.auditlogObject.toActionDate = null;
      this.auditLogAPI(this.auditlogObject);
    }
  }

  /**
  * (description): when date is choosed in filter this function is called
  * (parameter) : date
  * (memberof) : AuditLogComponent
  */
   formatedDate(str, code) {
    if (str == null) return null;
    let newStr = new Date(str);
    let changedUtcDate;
    if (str && code == constantStrings.STARTDATE) {
      let utcStartTime = new Date(
        newStr.getTime() + newStr.getTimezoneOffset() * 60000
      );
      changedUtcDate = utcStartTime.toISOString();
    } else if (str && code == constantStrings.ENDDATE) {
      newStr.setHours(0, 0, 0, 0);
      let endOfDay =
        newStr.getTime() +
        (24 * 60 * 60 * 1000 - 1) +
        newStr.getTimezoneOffset() * 60000;
      changedUtcDate = new Date(endOfDay).toISOString();
    }
    let formatedDate = formatDate(
      changedUtcDate,
      'MM/dd/yyyy HH:mm:ss',
      'en-US'
    );
    return formatedDate;
  }

  /**
  * (description): when searched for text in input this function is called
  * (parameter) : null
  * (memberof) : AuditComponent
  */
  searchData(event) {
    this.searchText = event?.target?.value;
  }

  /**
  * (description): when clicked on search button this function is called
  * (parameter) : null
  * (memberof) : AuditLogComponent
  */
  searchAuditLog() {
    this.isDisabled = false;
    this.auditlogObject.searchText = this.searchText;
    this.auditlogObject.pageno = this.pageStart;
    this.auditLogAPI(this.auditlogObject);
    this.auditlogExportObject.actionIds = [];
  }

  /**
* (description): when checkbox is selected from table this function is called and no selection export button disabled
* (parameter) : event, item
* (memberof) : AuditlogComponent
*/
  dataSelection(event, element) {
    this.isDisabled = true;
    if (!this.auditlogExportObject.actionIds.includes(element.actionId)) {
      this.auditlogExportObject.actionIds.push(element.actionId);
    } else {
      let index = this.auditlogExportObject.actionIds.indexOf(element.actionId);
      if (index >= 0) {
        this.auditlogExportObject.actionIds.splice(index, 1);
      }
      if (this.auditlogExportObject.actionIds.length == 0) {
        this.isDisabled = false;
      }
    }
  }

  /**
  * (description): when clicked on show more button this function is called
  * (parameter) : null
  * (memberof) : AuditLogComponent
  */
  showMore() {
    this.auditlogObject.pageno = this.auditlogObject.pageno + 1;
    this.auditlogObject.searchText = this.searchText
    this.auditLogAPI(this.auditlogObject);
  }
  /**
  * (description): method called on click of exported selected button
  * (parameter) : null
  * (memberof) : Component
  */
  exportSelected() {
    this.auditLogExportAPI(this.auditlogExportObject);
  }
  /**
  * (description): method called on click of exported all button
  * (parameter) : null
  * (memberof) : Component
  */
  exportAll() {
    this.spinnerService.show();
    const params = {
     userFacilityId: this.userInfo.userFacilityId,
      application:"duu"
    };
    this.auditlogService.postAuditExportAll(params).subscribe({
      next: result => {
        this.postAuditExportDetails = result;
        this.compilationFlag = true;
        this.downloadDone = false;
        this.logfileUrl = '';
        this.compilationModal = {
          display: 'block',
          content: '',
          cancelButton: '',
          closeFunction: undefined,
          data: undefined,
          header: '',
          okButton: '',
          submitFunction: undefined
        };
        this.spinnerService.hide();
      }, error: err => {
        this.errUserDetails = err;
        this.spinnerService.hide();
      }
    });
  }
  /**
  * (description): method called on click of exported all button
  * (parameter) : null
  * (memberof) : Component
  */
   getAuditLogUrl(str) {
    if (!this.downloadDone && !this.logfileUrl) {
      this.subscription = timer(0, this.intervalTime).pipe(
        switchMap(() => this.auditlogService.getAuditExportAll(this.userInfo.userFacilityId))
      ).subscribe(
        result => {
          this.getAuditExportDetails = result;
          this.logfileUrl = result.logfileurl;
          if (result.logfileurl) {
            if ((str == constantStrings.auditDownload || str == constantStrings.logDownload) && !this.downloadDone) {
              const a = document.createElement("a");
              a.href = result.logfileurl;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              this.auditLogActivity(constantStrings.AUDITEXPORT);
              this.downloadDone = true;
              this.intervalTime = null;
              this.subscription.unsubscribe();
            } else if (str == constantStrings.auditReady) {
              this.readyFlag = true;
              this.readyModal = {
                display: 'block',
                content: '',
                cancelButton: '',
                closeFunction: undefined,
                data: undefined,
                header: '',
                okButton: '',
                submitFunction: undefined
              }
            }
            this.compileCompleteDate = result.updatedDate;
          } else if (str == constantStrings.logDownload){
              this.expirationFlag = true;
              this.expirationModal = {
                display: 'block',
                content: '',
                cancelButton: '',
                closeFunction: undefined,
                data: undefined,
                header: '',
                okButton: '',
                submitFunction: undefined
            }
          }
        }
      );
    }
  }
  /**
  * (description): method called on click of download all logs button
  * (parameter) : null
  * (memberof) : Component
  */
  downloadLogs() {
    this.downloadDone = false;
    this.logfileUrl = '';
    this.getAuditLogUrl(constantStrings.logDownload);
  }
  /**
  * (description): method called on click of download button in ready popup
  * (parameter) : null
  * (memberof) : Component
  */
  displayReadyValue() {
    if(this.readyValueFlag){
      this.expirationFlag = true;
      this.expirationModal = {
        display: 'block',
        content: '',
        cancelButton: '',
        closeFunction: undefined,
        data: undefined,
        header: '',
        okButton: '',
        submitFunction: undefined
      }
    }
  }

  // This method is for sorting table
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }



  //This method is called to open Filter modal
  public openFilter(): void {
    this.filterFlag = true;
    this.filterModal = {     //modal for filter dropdown
      display: 'block',
      header: 'Filter By',
      content: '',
      doneButton: 'Done',
      submitFunction: () => { },
      okButton: null,
      cancelButton: null,
      closeFunction: undefined,
    };
  }

  // Function that displays the selected filter items
  displayFilterItems() {
    this.isdayChecked = false;
    this.auditlogObject.pageno = 0;
    this.auditlogObject.userName = this.selectedFilters.userName;
    let startDate = null, endDate = null;
    if(this.selectedFilters?.startDate){
       startDate = new Date(this.selectedFilters?.startDate);
    } else { startDate = null;}

    if(this.selectedFilters?.endDate){
      endDate = new Date(this.selectedFilters?.endDate);
   } else { endDate = null;}
    this.auditlogObject.fromActionDate = startDate === null ? startDate: this.getFormatedDatefilter(startDate);
    if (this.selectedFilters.startDate && !this.selectedFilters.endDate) {
      this.auditlogObject.toActionDate = this.getFormatedDatefilter(this.today);
    } else {
      this.auditlogObject.toActionDate = endDate === null ? endDate: this.getFormatedDatefilter(endDate);
    }
    this.auditLogAPI(this.auditlogObject);

    if (this.selectedFilters?.startDate && this.selectedFilters?.endDate) {
      this.selectedStartDate = this.selectedFilters?.startDate;
      this.startDateGradient = false;
    }
    if (this.selectedFilters?.endDate) {
      this.selectedEndDate = this.selectedFilters?.endDate;
      this.startDateGradient = false;
      this.startEndGradient = false;
    }
    if (this.selectedFilters?.startDate && !this.selectedFilters.endDate) {
      this.selectedStartDate = this.selectedFilters?.startDate;
      this.selectedEndDate = this.today;
      this.startDateGradient = false;
    }
    if(!this.selectedFilters?.startDate && !this.selectedFilters?.endDate){
      this.selectedStartDate = null;
      this.selectedEndDate = null;
    }
    if (this.selectedFilters?.userName) {
      this.selectedUsername = [];
      this.newList = this.selectedFilters.userName;
      for (let userName of this.FilterModel.apiUsernameRes) {
        for (let id of this.newList)
          if (id == userName.userName) {
            if (!this.selectedUsername.includes(userName.userName)) {
              this.selectedUsername.push(userName);
              this.startDateGradient = true;
              this.startEndGradient = true;
              this.userNameGradient = false;
            }
          }
      }
    }
    this.filterActiveFlag();
  }
  getFormatedDatefilter(date) {
    return (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
  }
  //Function that deletes the filter items and update in the filter pop-up
  deleteFilter(item, id) {
    if (id == constantStrings.STARTDATE || id == constantStrings.ENDDATE) {
      this.selectedStartDate = null;
      this.selectedEndDate = null;
      if (this.FilterModel) {
        if (id == constantStrings.STARTDATE) {
          this.FilterModel.onSelect(null, constantStrings.STARTDATE);
          this.displayFilterItems();
        } else {
          this.FilterModel.onSelect(null, constantStrings.ENDDATE);
          this.displayFilterItems();
        }
      }
    }
    if (id == constantStrings.userName) {
      for (let username of this.selectedUsername) {
        if (item == username) {
          this.selectedUsername.splice(this.selectedUsername.indexOf(username), 1);
        }
      }
      if (this.FilterModel) {
        this.FilterModel.onSelect(item.userName, constantStrings.userName);
        this.displayFilterItems();
      }
    }
    this.filterActiveFlag();
  }
  auditLogActivity(str) {
    switch (str) {
      case constantStrings.VIEW:
        this.auditText = this.auditAction.UserActions.auditlogView;
        this.auditId = null;
        break;
      case constantStrings.SEARCH:
        this.auditText = this.auditAction.UserActions.searchLog;
        this.auditId = 'Audit Log page';
        break;
      case constantStrings.FILTER:
        this.auditText = this.auditAction.UserActions.filterLog;
        this.auditId = null;
        break;
      case constantStrings.AUDITEXPORT:
        this.auditText = this.auditAction.UserActions.auditLogExport;
        this.auditId = null;
        break;
      default:
        this.auditText = '';
        this.auditId = null;
        break;
    }
    let actionOutcome = {
      "userFacilityId": this.userInfo.userFacilityId,
      "userName": this.userInfo.userName,
      "action": this.auditId ? this.auditText + '' + this.auditId : this.auditText,
      "eventOutCome": this.featureOutcomeEvent(str)
    }
    this.auditlogService.auditLogActivity(actionOutcome).subscribe({
      next: result => {
        this.actionActivityDetails = result;
      }, error: err => {
        this.errUserDetails = err;
      }
    });
  }
  /**
   * (description): To call function featureActionOutcome which return event outcome for auditLog API
   * (memberof) : Component
  **/
  featureOutcomeEvent(str) {
    switch (str) {
      case constantStrings.VIEW:
        return this.auditLogDetails ? 1 : 0;
      case constantStrings.SEARCH:
        return this.auditLogDetails ? 1 : 0;
      case constantStrings.FILTER:
        return this.auditLogDetails ? 1 : 0;
      case constantStrings.AUDITEXPORT:
        return this.errorAuditLog ? 0 : 1;
      default:
        return null;
    }
  }
}
