import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { of } from 'rxjs';
import { delay, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileDashboardFilterComponent } from '../filedashboardfilter/filedashboardfilter.component';
import { constantStrings, ROLE } from '../shared/helpers/constants';
import { statusCode } from '../shared/helpers/constants';
import { statusCodeString } from '../shared/helpers/constants';
import { FilterModal, INITIAL_FILTERMODAL_STATE, INITIAL_MODAL_STATE, INITIAL_RECALLMODAL_STATE, Modal, RecallModal } from '../shared/models/dialog.model';
import { SharedStateService } from '../shared/services/shared-state.service';
import { FileDashboardService } from './filedashboard.service';
import { DashboardData, DashboardResponseData } from '../shared/models/user.model';
import { AuthService } from '../shared/services/auth.service';
import { settingsResponse } from 'src/app/shared/models/user.model';
import { UploadService } from '../uploadfiles/upload.service';
import { environment } from 'src/environments/environment';
import { DatePipe, formatDate } from '@angular/common';
import { AlertDialogService } from '../shared/components/alert-dialog/alert-dialog.service';
import { UploadIncompleteComponent } from './uploadincomplte-dialog/uploadincomplte-dialog.component';

@Component({
  selector: 'app-filedashboard',
  templateUrl: './filedashboard.component.html',
  styleUrls: ['./filedashboard.component.scss']
})
export class FiledashboardComponent implements OnInit {
  dashboardObject: DashboardData;
  showDashboard = true;
  showErrorRetrieveData = false;
  errorDetailsRes = [];
  errorInFiledashboard = [];
  filedashboardRes;
  filedashboardResLength: number;
  filedashboardResTotalPages: number;
  errDasboardErrorDetails: string;
  pagelimit: number;
  StatusCodeUpload: number;
  StatusCodeProcessing: number;
  StatusCodeComplete: number;
  StatusCodeError: number;
  StatusCodeStringUpload: string;
  StatusCodeStringProcessing: string;
  StatusCodeStringComplete: string;
  StatusCodeStringError: string;
  isErrorDashboard;
  notification;
  unreadErrorsPresent: number;
  Error: string;
  isProcessingChecked: boolean = false;
  isuploadedChecked: boolean = false;
  isCompleteChecked: boolean = false;
  isErrorChecked: boolean = false;
  isdayChecked: boolean = false;
  status;
  public recallModalUi: RecallModal = INITIAL_RECALLMODAL_STATE;
  @Input() modal: Modal = INITIAL_MODAL_STATE;
  UPLOADED: string;
  uploadedDateTime: any;
  isRecalled: boolean;
  itemIndex: number;
  newSubscription: any;
  readUnreadFailed: boolean;
  openFilterFlag: boolean = false;
  startDateGradient: boolean = true;
  surgeonGradient: boolean = true;
  siteGradient: boolean = true;
  appGradient: boolean = true;
  statusGradient: boolean = true;
  startEndGradient: boolean;
  openRecallFlag: boolean;
  acknowledgeItem: any;
  auditAction: any;
  initialFlag: boolean = false;
  recallTime: number;
  ssmResponse: string;
  snnUserRole: boolean = false;
  patientdate = [];
  recallObj: DashboardResponseData;
  recallUploadId: string;
  selectedRecallValue: boolean;
  //incomplete popup
  isExistPatientFirstName = true;
  isExistPatientLastName = true;
  isExistPatientDOB = true;
  isExistStudyDescription = true;
  openUploadIncomplete: boolean;
  fileData: any;

  receiveValue($event) {
    this.showErrorRetrieveData = $event;
    this.showDashboard = true;
  }
  @ViewChild(FileDashboardFilterComponent) FilterModel;
  public addModalUi: FilterModal = INITIAL_FILTERMODAL_STATE;
  selectedFilters;
  selectedSurgeon = [];
  selectedSite = [];
  selectedApp = [];
  selectedStatus = [];
  newList = [];
  isSurgeonTrue;
  selectedItems = [];
  searchText: string;
  filterActive: boolean = false;
  selectedStartDate: Date;
  selectedEndDate: Date;
  public today: Date = new Date();
  userInfo: any;
  actionActivityDetails: settingsResponse;
  errOutcome: number = 0;
  successOutcome: number = 1;
  eventOutcome: number;
  auditText: string | number;
  auditId: string | number;
  uploadFilesList = [];
  StatusCodeUploadPending: number;
  StatusCodeFailed: number;
  isUpdateSuccess = false;
  StatusCodeCorrupted:number;
  //receives the selected filter values sent from filedashboardfilter component
  receiveList($event) {
    this.selectedFilters = $event;
  }
  receiveRecallValue($event) {
    this.selectedRecallValue = $event;
  }

  constructor(private sharedState: SharedStateService,
    private filedashboardService: FileDashboardService,
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private authService: AuthService,
    private uploadService: UploadService,
    private datePipe: DatePipe,
    private alertDialogService: AlertDialogService,
  ) {
    this.StatusCodeUpload = statusCode.UPLOADED;
    this.StatusCodeProcessing = statusCode.PROCESSING;
    this.StatusCodeComplete = statusCode.COMPLETE;
    this.StatusCodeError = statusCode.ERROR;
    this.StatusCodeStringUpload = statusCodeString.UPLOADED
    this.StatusCodeStringProcessing = statusCodeString.PROCESSING
    this.StatusCodeStringComplete = statusCodeString.COMPLETE
    this.StatusCodeStringError = statusCodeString.ERROR
    this.StatusCodeUploadPending = statusCode.UPLOADPENDING
    this.StatusCodeFailed = statusCode.FAILED
    this.pagelimit = statusCode.PageLimit;
    this.StatusCodeCorrupted = statusCode.CORRUPTED;
    this.ssmInfo();
    //To get user actions from json file
    this.sharedState.userActionStateData$.subscribe(response => {
      this.auditAction = response;
    });
    if (this.authService.getItem('userDetails')) {
      this.loadFileDashboard();
    }
  }

  ngOnInit(): void {
    //passing boolen value of display selection to state service 
    this.isSurgeonTrue = {
      isDisplaySelectionfacility: false,
      isDisplaySelectionSurgeon: false,
      isDisplaySelectionApplication: false,
      isUploadFailed: false,
      isHelpScreen: false

    }
    this.sharedState.setDashboardStateData(this.isSurgeonTrue);
    let notification = {
      displayUploadFile: true,
      displaySiteModal: false,
      displaySurgeonModal: false,
      displayApplicationModal: false,
      displaySelectFilesModal: false
    }
    this.sharedState.popupCancelData(notification);
    // get dashboard isRecalled value stored in shared state service
    this.sharedState.fileDashboardStateData$.subscribe(response => {       //get application object stored in state service
      this.isRecalled = response?.isRecalled;
    });
  }
  /**
    * @description to set recall record flag in dashboard data and hide recalled record
    * (parameter) : null
    * @memberof component
  */
  recallRecord() {
    if (this.selectedRecallValue) {
      this.sharedState.fileDashboardStateData$.subscribe(response => {
        this.isRecalled = response.isRecalled;
        this.recallUploadId = response.uploadId;
      });
      for (let data of this.filedashboardRes) {
        if (this.recallUploadId == data.uploadId) {
          data.recalledData = true;
        }
      }
    } else {
      this.isRecalled = false;
    }
  }

  /**
    * @description to validate user role for recall button
    * (parameter) : role
    * @memberof component
  */
  recallUserRole(role) {
    if (role.toLowerCase() == ROLE.SNN_HIL_MANAGER) {
      this.snnUserRole = false;
    } else {
      this.snnUserRole = true;
    }
  }

  /**
    * @description to get the SSM parameter values
    * @memberof component
  */
  public ssmInfo() {
    this.spinnerService.show();
    this.authService.getSSMValue(environment.ssmParams.RECALLTIME);
  }

  /**
  * @description function called to format date for API request
  * @memberof component
  */
  formatedDate(str, code) {
    if (str == null) return null;
    let newStr = new Date(str);
    let changedUtcDate;
    if (str && code == constantStrings.STARTDATE) {
      let utcStartTime = new Date(
        newStr.getTime() + newStr.getTimezoneOffset() * 60000
      );
      changedUtcDate = utcStartTime.toISOString();
    } else if (str && code == constantStrings.ENDDATE) {
      newStr.setHours(0, 0, 0, 0);
      let endOfDay =
        newStr.getTime() +
        (24 * 60 * 60 * 1000 - 1) +
        newStr.getTimezoneOffset() * 60000;
      changedUtcDate = new Date(endOfDay).toISOString();
    }
    let formatedDate = formatDate(
      changedUtcDate,
      'MM/dd/yyyy HH:mm:ss',
      'en-US'
    );
    return formatedDate;
  }

  // Function that displays the selected filter items
  displayFilterItems() {
    this.isProcessingChecked = false;
    this.isuploadedChecked = false;
    this.isCompleteChecked = false;
    this.isErrorChecked = false;
    this.isdayChecked = false;
    if (this.selectedFilters.status && this.selectedFilters.status.length != 0) {
      this.dashboardObject.statuses = [];
      for (let status of this.selectedFilters.status) {
        if (status.status.toLowerCase() == statusCodeString.UPLOADED && !this.dashboardObject.statuses.includes(statusCode.UPLOADED)) this.dashboardObject.statuses.push(this.StatusCodeUpload);
        if (status.status.toLowerCase() == statusCodeString.PROCESSING && !this.dashboardObject.statuses.includes(statusCode.PROCESSING)) this.dashboardObject.statuses.push(this.StatusCodeProcessing);
        if (status.status.toLowerCase() == statusCodeString.COMPLETE && !this.dashboardObject.statuses.includes(statusCode.COMPLETE)) this.dashboardObject.statuses.push(this.StatusCodeComplete);
        if (status.status.toLowerCase() == statusCodeString.ERROR && !this.dashboardObject.statuses.includes(statusCode.ERROR)) this.dashboardObject.statuses.push(this.StatusCodeError);
        if (status.status.toLowerCase() == statusCodeString.UNPROCESSED && !this.dashboardObject.statuses.includes(statusCode.UNPROCESSED)) this.dashboardObject.statuses.push(statusCode.UNPROCESSED);
        if (status.status.toLowerCase() == statusCodeString.RECALLED && !this.dashboardObject.statuses.includes(statusCode.RECALLED)) this.dashboardObject.statuses.push(statusCode.RECALLED);
        if (status.status.toLowerCase() == statusCodeString.GROUPED && !this.dashboardObject.statuses.includes(statusCode.GROUPED)) this.dashboardObject.statuses.push(statusCode.GROUPED);
      }
    } else {
      this.dashboardObject.statuses = [];
    }
    //Asigning filter values to api parameters
    this.dashboardObject.applicationIds = this.selectedFilters.application;
    this.dashboardObject.surgeonIds = this.selectedFilters.surgeon;
    this.dashboardObject.targetFacilityIds = this.selectedFilters.site;
    this.dashboardObject.fromUploadDate = this.formatedDate(this.selectedFilters.startDate, constantStrings.STARTDATE);
    if (this.selectedFilters.startDate && !this.selectedFilters.endDate) {
      this.dashboardObject.toUploadDate = this.formatedDate(this.today, constantStrings.ENDDATE);
    } else {
      this.dashboardObject.toUploadDate = this.formatedDate(this.selectedFilters.endDate, constantStrings.ENDDATE);
    }
    this.postFileDashboard(this.dashboardObject);
    if (this.selectedFilters?.startDate && this.selectedFilters?.endDate) {
      this.selectedStartDate = this.selectedFilters?.startDate;
      this.startDateGradient = false;
    }
    if (this.selectedFilters?.endDate) {
      this.selectedEndDate = this.selectedFilters?.endDate;
      this.startDateGradient = false;
      this.startEndGradient = false;
    }
    if (this.selectedFilters?.startDate && !this.selectedFilters.endDate) {
      this.selectedStartDate = this.selectedFilters?.startDate;
      this.selectedEndDate = this.today;
      this.startDateGradient = false;
    }
    if (!this.selectedFilters?.startDate && !this.selectedFilters?.endDate) {
      this.selectedStartDate = null;
      this.selectedEndDate = null;
    }
    if (this.selectedFilters?.surgeon) {
      this.selectedSurgeon = [];
      this.newList = this.selectedFilters.surgeon;
      for (let surgeon of this.FilterModel.apiSurgeonRes) {
        for (let id of this.newList)
          if (id == surgeon.surgeonId) {
            if (!this.selectedSurgeon.includes(surgeon.surgeonId)) {
              this.selectedSurgeon.push(surgeon);
              this.startDateGradient = true;
              this.startEndGradient = true;
              this.surgeonGradient = false;
            }
          }
      }
    }
    if (this.selectedFilters?.site) {
      this.selectedSite = [];
      this.newList = this.selectedFilters.site;
      for (let site of this.FilterModel.apiSiteRes) {
        for (let id of this.newList)
          if (id == site.facilityId) {
            if (!this.selectedSite.includes(site.facilityId)) {
              this.selectedSite.push(site);
              this.startDateGradient = true;
              this.startEndGradient = true;
              this.surgeonGradient = true;
              this.siteGradient = false;
            }
          }
      }
    }
    if (this.selectedFilters?.application) {
      this.selectedApp = [];
      this.newList = this.selectedFilters.application;
      for (let app of this.FilterModel.apiApplicationRes) {
        for (let id of this.newList)
          if (id == app.applicationId) {
            if (!this.selectedApp.includes(app.applicationId)) {
              this.selectedApp.push(app);
              this.startDateGradient = true;
              this.startEndGradient = true;
              this.surgeonGradient = true;
              this.siteGradient = true;
              this.appGradient = false;
            }
          }
      }
    }
    if (this.selectedFilters?.status) {
      this.selectedStatus = [];
      this.newList = this.selectedFilters.status;
      for (let status of this.FilterModel.apiStatusRes) {
        for (let id of this.newList)
          if (id == status) {
            if (!this.selectedStatus.includes(status)) {
              this.selectedStatus.push(status);
              this.startDateGradient = true;
              this.startEndGradient = true;
              this.surgeonGradient = true;
              this.siteGradient = true;
              this.appGradient = true;
              this.statusGradient = false;
            }
          }
      }
    }
    this.filterActiveFlag();
  }

  /**
  * (description): calling API to read an error for a particular record sucessfully
  * (parameter) : uploadId, isRead
  * (memberof) : FiledashboardComponent
  */
  public errorDetailsAPI(params): any {
    this.spinnerService.show();
    this.sharedState.fileDashboardStateData$.subscribe(response => {
      this.readUnreadFailed = response.isReadUnreadFailed;
    });
    this.filedashboardService.acknowledgeErrorDetails(params).subscribe({
      next: result => {
        this.errorDetailsRes = result;
        this.auditLogActivity(params.isRead, this.successOutcome)
        this.spinnerService.hide();
      }, error: err => {
        this.errDasboardErrorDetails = err;
        this.readUnreadFailed = true;
        let isReadUnreadFailed = {
          isReadUnreadFailed: this.readUnreadFailed,
        }
        this.sharedState.displayDashboardError(isReadUnreadFailed);
        this.auditLogActivity(params.isRead, this.errOutcome)
        this.spinnerService.hide();
      }
    });
  }

  /**
   * (description): calling API to fetch uploaded dashboard records
   * (parameter) : uploadId, isRead
   * (memberof) : FiledashboardComponent
   */
  public async postFileDashboard(params) {
    this.spinnerService.show();
    this.isErrorDashboard = false;
    this.notification = {
      isErrorDashboard: this.isErrorDashboard
    }
    this.authService.setItem('isErrorDashboard', this.notification);
    let statusParams={
      "facilityId":this.userInfo.userFacilityId ,
      "oneDay": this.isdayChecked ? 1 : 0
    }
    await this.getUploadFileStatus(statusParams);
    this.filterAppliedFlag();
    this.filedashboardService.postFileDashboard(params).subscribe(
      {

        next: result => {

          if (this.searchText) {
            this.auditLogActivity(constantStrings.SEARCH, this.successOutcome);
          }
          if (this.filterActive) {
            this.auditLogActivity(constantStrings.FILTER, this.successOutcome);
          }
          if (this.initialFlag) {
            this.auditLogActivity(statusCodeString.COMPLETE, this.successOutcome);
          }
          this.filedashboardResLength = result.totalElements;
          this.filedashboardResTotalPages = result.totalPages;
          //Read values of error and uploaded from constants
          this.Error = constantStrings.ERROR;
          this.UPLOADED = constantStrings.UPLOADED
          if (result && result.dashboardDetails) {
            this.patientdate = result.dashboardDetails.map(obj => ({ ...obj, isStatusRecord: false }));;
            for (let patientDetails of this.patientdate) {
              const auditlogData = patientDetails;
              const utcDatetime = auditlogData.uploadDate;
              const utcDate = new Date(utcDatetime);
              const createdDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000); // Convert to local timezone
              //Converting data object to string format to display as per figma
              let fixedDatetime = createdDate.toISOString();
              patientDetails['uploadDateDisplay'] = this.datePipe.transform(fixedDatetime, 'MM/dd/yyyy');
              if (patientDetails.patientDOB && !(patientDetails.patientDOB.includes("/"))) {
                let patientDob = patientDetails.patientDOB;
                let patientYear = patientDob.substring(0, 4);
                let patientMonth = patientDob.substring(4, 6);
                let patientDate = patientDob.substring(6, 8);
                let finalDOB = patientMonth + '/' + patientDate + '/' + patientYear;
                patientDetails.patientDOB = finalDOB;
              }
            }
            if (this.filedashboardService.isFilterApplied) {
              this.filedashboardRes = this.patientdate;
            } else {
              let tempData = [];
              if (this.isErrorChecked) {
                tempData = this.uploadFilesList.filter(x => x.status == this.StatusCodeFailed);
                if (tempData.length == 0)
                  this.uploadFilesList = [];
              } else {
                if (this.isuploadedChecked || this.isProcessingChecked || this.isCompleteChecked) {
                  tempData = [];
                  this.uploadFilesList = [];
                } else {
                  tempData = this.uploadFilesList;
                }
              }
              this.filedashboardRes = [...tempData, ...this.patientdate]
            }
            // Check if any record with error status is present display error notification
            for (let statuses of this.patientdate) {
              if (statuses.status == this.Error && statuses.isRead == false) {
                this.isErrorDashboard = true;
                this.notification = {
                  isErrorDashboard: this.isErrorDashboard
                }
                this.authService.setItem('isErrorDashboard', this.notification);
                this.sharedState.displayDashboardError(this.notification);
              }
            }
            const timeCheck = of(null);
            for (let items of this.patientdate) {
              const itemIndex = this.patientdate.indexOf(items);
              if (items.status == this.UPLOADED || (items.errorInfo.toLowerCase() === constantStrings.uploadIncomplete && items.uploadInCompleteEditable ==2)) {
                this.uploadedDateTime = items.uploadDate;
                const dt = new Date(this.uploadedDateTime);
                var offset = new Date().getTimezoneOffset() * -1;
                dt.setMinutes(dt.getMinutes() + (this.recallTime + offset));
                //Comparing current time with uploaded time to display recall button
                if (dt >= this.today) {
                  items.hideRecallFlag = true;
                } else {
                  items.hideRecallFlag = false;
                }
                this.newSubscription = timeCheck.pipe(delay(dt),).subscribe(() => {
                  const element = document.querySelectorAll('.recall_button');
                  if (element) {
                    for (let i = 0; i < element.length; i++) {
                      if ((+element[i].id == itemIndex) && (items.hideRecallFlag)) {
                        element[i].classList.add(constantStrings.hideRecall);
                        items.hideRecallFlag = false;
                      }
                    }
                  }
                });
              }
            }
          }
          else {
            if (this.isErrorChecked) {
              this.filedashboardRes = this.uploadFilesList.filter(x => x.status == this.StatusCodeFailed);
              if (this.filedashboardRes)
                this.uploadFilesList = [];
            } else {
              if (this.isuploadedChecked || this.isProcessingChecked || this.isCompleteChecked) {
                this.filedashboardRes = [];
                this.uploadFilesList = [];
              } else {
                this.filedashboardRes = this.uploadFilesList;
              }
            }
          }
          this.spinnerService.hide();
        }, error: error => {
          this.errorInFiledashboard = error;
          if (this.searchText) {
            this.auditLogActivity(constantStrings.SEARCH, this.errOutcome);
          }
          if (this.filterActive) {
            this.auditLogActivity(constantStrings.FILTER, this.errOutcome);
          }
          if (this.initialFlag) {
            this.auditLogActivity(statusCodeString.COMPLETE, this.eventOutcome);
          }
          this.auditLogActivity(statusCodeString.ERROR, this.errOutcome);
          this.router.navigate(['/filedashboard/dashboarderror']);
          this.spinnerService.hide();
        }
      });
  }

  /**
  * (description): method called on click of progressing quick filter
  * (parameter) : id
  * (memberof) : Component
  */
  processChecked(id) {
    this.selectedStartDate = null;
    this.selectedEndDate = null;
    this.selectedSurgeon = [];
    this.selectedSite = [];
    this.selectedApp = [];
    this.selectedStatus = [];
    if (this.filterActive) {
      this.filterActive = false;
      this.dashboardObject.statuses = [];
      this.dashboardObject.surgeonIds = [];
      this.dashboardObject.applicationIds = [];
      this.dashboardObject.targetFacilityIds = [];
      this.dashboardObject.fromUploadDate = null;
      this.dashboardObject.toUploadDate = null;
    }
    if (this.FilterModel != undefined) {
      this.FilterModel.clearAll();
    }
    if (id == this.StatusCodeUpload) this.isuploadedChecked = !this.isuploadedChecked
    if (id == this.StatusCodeProcessing) this.isProcessingChecked = !this.isProcessingChecked
    if (id == this.StatusCodeComplete) this.isCompleteChecked = !this.isCompleteChecked
    if (id == this.StatusCodeError) this.isErrorChecked = !this.isErrorChecked
    if (this.isProcessingChecked == true && !this.dashboardObject.statuses.includes(this.StatusCodeProcessing)) {
      this.dashboardObject.statuses.push(this.StatusCodeProcessing);
      this.postFileDashboard(this.dashboardObject);
    }
    if (this.isProcessingChecked == false) {
      let index = this.dashboardObject.statuses.indexOf(this.StatusCodeProcessing);
      if (index >= 0) {
        this.dashboardObject.statuses.splice(index, 1);
        this.postFileDashboard(this.dashboardObject);
      }
    }
    if (this.isuploadedChecked == true && !this.dashboardObject.statuses.includes(this.StatusCodeUpload)) {
      this.dashboardObject.statuses.push(this.StatusCodeUpload);
      this.postFileDashboard(this.dashboardObject);
    }
    if (this.isuploadedChecked == false) {
      let index = this.dashboardObject.statuses.indexOf(this.StatusCodeUpload);
      if (index >= 0) {
        this.dashboardObject.statuses.splice(index, 1);
        this.postFileDashboard(this.dashboardObject);
      }
    }

    if (this.isCompleteChecked == true && !this.dashboardObject.statuses.includes(this.StatusCodeComplete)) {
      this.dashboardObject.statuses.push(this.StatusCodeComplete);
      this.postFileDashboard(this.dashboardObject);
    }
    if (this.isCompleteChecked == false) {
      let index = this.dashboardObject.statuses.indexOf(this.StatusCodeComplete);
      if (index >= 0) {
        this.dashboardObject.statuses.splice(index, 1);
        this.postFileDashboard(this.dashboardObject);
      }
    }

    if (this.isErrorChecked == true && !this.dashboardObject.statuses.includes(this.StatusCodeError)) {
      this.dashboardObject.statuses.push(this.StatusCodeError);
      this.postFileDashboard(this.dashboardObject);
    }
    if (this.isErrorChecked == false) {
      let index = this.dashboardObject.statuses.indexOf(this.StatusCodeError);
      if (index >= 0) {
        this.dashboardObject.statuses.splice(index, 1);
        this.postFileDashboard(this.dashboardObject);
      }
    }
  }
  /**
  * (description): method called on click of search button
  * (parameter) : null
  * (memberof) : Component
  */
  searchDashboard() {
    this.dashboardObject.searchText = this.searchText;
    this.postFileDashboard(this.dashboardObject);
  }
  /**
  * (description): method called on click of 24 hours quick filter
  * (parameter) : null
  * (memberof) : Component
  */
  dayCheckChecked() {
    this.selectedStartDate = null;
    this.selectedEndDate = null;
    this.selectedSurgeon = [];
    this.selectedSite = [];
    this.selectedApp = [];
    this.selectedStatus = [];
    if (this.filterActive) {
      this.filterActive = false;
      this.dashboardObject.statuses = [];
      this.dashboardObject.surgeonIds = [];
      this.dashboardObject.applicationIds = [];
      this.dashboardObject.targetFacilityIds = [];
      this.dashboardObject.fromUploadDate = null;
      this.dashboardObject.toUploadDate = null;
    }
    if (this.FilterModel != undefined) {
      this.FilterModel.clearAll();
    }
    this.isdayChecked = !this.isdayChecked;
    if (this.isdayChecked == true) {
      let twentyfourHours = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
      this.dashboardObject.fromUploadDate = this.formatedDate(twentyfourHours, constantStrings.STARTDATE);
      this.dashboardObject.toUploadDate = this.formatedDate(new Date(new Date().getTime()), constantStrings.ENDDATE);
      this.postFileDashboard(this.dashboardObject);
    }
    if (this.isdayChecked == false) {
      this.dashboardObject.fromUploadDate = null;
      this.dashboardObject.toUploadDate = null;
      this.postFileDashboard(this.dashboardObject);
    }


  }
  /**
  * (description): method called on click of load more button
  * (parameter) : null
  * (memberof) : Component
  */
  loadMore() {
    this.pagelimit = this.pagelimit + 10;
    this.dashboardObject.limit = this.pagelimit;
    this.postFileDashboard(this.dashboardObject);
  }

  // Function to open filter modal
  public openFilter(): void {
    this.openFilterFlag = true;
    this.addModalUi = {     //modal for filter dropdown
      display: 'block',
      header: 'Filter By',
      content: '',
      doneButton: 'Done',
      submitFunction: () => { },
      okButton: null,
      cancelButton: null,
      closeFunction: undefined,
    };
  }

  //Function that deletes the filter items and update in the filter pop-up
  deleteFilter(item, id) {
    if (id == constantStrings.STARTDATE || id == constantStrings.ENDDATE) {
      this.selectedStartDate = null;
      this.selectedEndDate = null;
      if (this.FilterModel) {
        if (id == constantStrings.STARTDATE) {
          this.FilterModel.onSelect(null, constantStrings.STARTDATE);
          this.displayFilterItems();
        } else {
          this.FilterModel.onSelect(null, constantStrings.ENDDATE);
          this.displayFilterItems();
        }
      }
    }
    if (id == constantStrings.surgeonGroup) {
      for (let surgeon of this.selectedSurgeon) {
        if (item == surgeon) {
          this.selectedSurgeon.splice(this.selectedSurgeon.indexOf(surgeon), 1);
        }
      }
      if (this.FilterModel) {
        this.FilterModel.onSelect(item.surgeonId, constantStrings.surgeonGroup);
        this.displayFilterItems();
      }
    }
    if (id == constantStrings.SITE) {
      for (let site of this.selectedSite) {
        if (item == site) {
          this.selectedSite.splice(this.selectedSite.indexOf(site), 1);
        }
      }
      if (this.FilterModel) {
        this.FilterModel.onSelect(item.facilityId, constantStrings.SITE);
        this.displayFilterItems();
      }
    }
    if (id == constantStrings.APPLICATION) {
      for (let app of this.selectedApp) {
        if (item == app) {
          this.selectedApp.splice(this.selectedApp.indexOf(app), 1);
        }
      }
      if (this.FilterModel) {
        this.FilterModel.onSelect(item.applicationId, constantStrings.APPLICATION);
        this.displayFilterItems();
      }
    }
    if (id == constantStrings.STATUS) {
      for (let status of this.selectedStatus) {
        if (item == status) {
          this.selectedStatus.splice(this.selectedStatus.indexOf(status), 1);
        }
      }
      if (item && item.status && (item.status.toLowerCase() == statusCodeString.UPLOADED)) {
        let index = this.dashboardObject.statuses.indexOf(statusCode.UPLOADED);
        if (index >= 0) {
          this.dashboardObject.statuses.splice(index, 1);
          this.postFileDashboard(this.dashboardObject);
        }
      }

      if (item && item.status && (item.status.toLowerCase() == statusCodeString.PROCESSING)) {
        let index = this.dashboardObject.statuses.indexOf(statusCode.PROCESSING);
        if (index >= 0) {
          this.dashboardObject.statuses.splice(index, 1);
          this.postFileDashboard(this.dashboardObject);
        }
      }

      if (item && item.status && (item.status.toLowerCase() == statusCodeString.COMPLETE)) {
        let index = this.dashboardObject.statuses.indexOf(statusCode.COMPLETE);
        if (index >= 0) {
          this.dashboardObject.statuses.splice(index, 1);
          this.postFileDashboard(this.dashboardObject);
        }
      }

      if (item && item.status && (item.status.toLowerCase() == statusCodeString.ERROR)) {
        let index = this.dashboardObject.statuses.indexOf(statusCode.ERROR);
        if (index >= 0) {
          this.dashboardObject.statuses.splice(index, 1);
          this.postFileDashboard(this.dashboardObject);
        }
      }

      if (item && item.status && (item.status.toLowerCase() == statusCodeString.UNPROCESSED)) {
        let index = this.dashboardObject.statuses.indexOf(statusCode.UNPROCESSED);
        if (index >= 0) {
          this.dashboardObject.statuses.splice(index, 1);
          this.postFileDashboard(this.dashboardObject);
        }
      }

      if (item && item.status && (item.status.toLowerCase() == statusCodeString.RECALLED)) {
        let index = this.dashboardObject.statuses.indexOf(statusCode.RECALLED);
        if (index >= 0) {
          this.dashboardObject.statuses.splice(index, 1);
          this.postFileDashboard(this.dashboardObject);
        }
      }

      if (item && item.status && (item.status.toLowerCase() == statusCodeString.GROUPED)) {
        let index = this.dashboardObject.statuses.indexOf(statusCode.GROUPED);
        if (index >= 0) {
          this.dashboardObject.statuses.splice(index, 1);
          this.postFileDashboard(this.dashboardObject);
        }
      }

      if (this.FilterModel) {
        this.FilterModel.onSelect(item, constantStrings.STATUS);
        this.displayFilterItems();
      }
    }

    this.filterActiveFlag();
  }
  /**
  * (description): method called display the filtered items
  * (parameter) : null
  * (memberof) : Component
  */
  filterActiveFlag() {
    if (this.selectedFilters.application.length > 0 || this.selectedFilters.site.length > 0 || this.selectedFilters.startDate || this.selectedFilters.endDate || this.selectedFilters.status.length > 0 || this.selectedFilters.surgeon.length > 0) {
      this.filterActive = true;
    } else {
      this.filterActive = false;
    }
  }

  /**
   * (description): Function calls on click of recall button
   * (parameter) : listItem
   * (parameter) : _index
   * (memberof) : FiledashboardComponent
   */
  public onRecall(listItem, _index): void {
    this.recallObj = listItem;
    this.openRecallFlag = true;
    this.recallModalUi = {
      display: 'block',
      content: '',
      okButton: 'Confirm',
      cancelButton: 'Cancel',
      submitFunction: () => { },
      closeFunction: () => { },
      header: ''
    };
    this.selectedItems.push(_index);
    this.itemIndex = _index;

    let selectedItem = {
      selectedDashboardItem: listItem,
      uploadedDateTime: listItem.uploaded
    }
    this.sharedState.displayDashboardRecall(selectedItem);
  }

  public onUploadIncomplete(listItem, _index): void {
    this.fileData = listItem;
    this.openUploadIncomplete = true;
    this.recallModalUi = {
      display: 'block',
      content: '',
      okButton: 'Confirm',
      cancelButton: 'Cancel',
      submitFunction: () => { },
      closeFunction: () => { },
      header: ''
    };
    //this.selectedItems.push(_index);
    this.itemIndex = _index;

    let selectedItem = {
      selectedDashboardItem: listItem,
      uploadedDateTime: listItem.uploaded
    }
    //this.sharedState.displayDashboardRecall(selectedItem);
  }


  /**
   * (description): Function calls on click of achnowledge/unread button
   * (parameter) : listItem
   * (parameter) : _index
   * (memberof) : FiledashboardComponent
   */
  public onAcknowledge(listItem, _index: number): void {
    this.acknowledgeItem = listItem;
    const body = {
      "uploadId": listItem?.uploadId,
      "isRead": !(listItem?.isRead)
    }
    //call error details api function
    this.errorDetailsAPI(body);
    //Get isReadUnreadFailed value stored in local storage
    this.sharedState.fileDashboardStateData$.subscribe(response => {
      this.readUnreadFailed = response.isReadUnreadFailed;
    })
    //If Api response is Success on click of particular record change from unread to acknowledge and vice versa
    if (this.readUnreadFailed != true) {
      if (_index !== -1) {
        this.filedashboardRes[_index].isRead = !this.filedashboardRes[_index].isRead;
      }
    }

    this.unreadErrorsPresent = 0;
    //check if any error is not acknowledged
    for (let statuses of this.filedashboardRes) {
      if (statuses.status == this.Error && statuses.isRead == false) {
        this.unreadErrorsPresent += 1;
      }
    }
    //If any unread error is present display error notification in sidemenu
    if (this.unreadErrorsPresent >= 1) {
      this.isErrorDashboard = true;
      this.notification = {
        isErrorDashboard: this.isErrorDashboard
      }
      this.authService.setItem('isErrorDashboard', this.notification);
      this.sharedState.displayDashboardError(this.notification);
    }
    else {
      //hide error notification if no unread errors present
      this.isErrorDashboard = false;
      this.notification = {
        isErrorDashboard: this.isErrorDashboard
      }
      this.authService.removeItem('isErrorDashboard');
      this.sharedState.displayDashboardError(this.notification);
    }
  }

  /**
    * (description): To call audit log activity function
    * (memberof) : Component
   **/
  auditLogActivity(code, outcome) {
    if (this.userInfo) {
      switch (code) {
        case statusCodeString.COMPLETE:
          this.auditText = this.auditAction.UserActions.dashboardDataViewed;
          this.auditId = null;
          break;
        case true:
          this.auditText = this.auditAction.UserActions.uploadAcknowledged;
          this.auditId = this.acknowledgeItem.uploadId;
          break;
        case false:
          this.auditText = this.auditAction.UserActions.uploadUnread;
          this.auditId = this.acknowledgeItem.uploadId;
          break;
        case constantStrings.SEARCH:
          this.auditText = this.auditAction.UserActions.searchLog;
          this.auditId = 'File Dashboard page';
          break;
        case constantStrings.FILTER:
          this.auditText = this.auditAction.UserActions.filterLog;
          this.auditId = null;
          break;
        default:
          this.auditText = '';
          this.auditId = null;
          break;
      }
      this.eventOutcome = outcome;
      let actionOutcome = {
        "userFacilityId": this.userInfo.userFacilityId,
        "userName": this.userInfo.userName,
        "action": this.auditId ? this.auditText + '' + this.auditId : this.auditText,
        "eventOutCome": this.eventOutcome
      }
      this.filedashboardService.auditLogActivity(actionOutcome).subscribe({
        next: result => {
          this.actionActivityDetails = result;
        }, error: err => {
          this.errDasboardErrorDetails = err;
        }
      });
    }
    this.initialFlag = false;
  }

  ngOnDestroy() {
    this.newSubscription?.unsubscribe();
  }

  callUploadIncomplete(eventValue) {
    this.openUploadIncomplete = false;
    if(eventValue==true)
    this.isUpdateSuccess = true;
  }

  getUploadFileStatus(params) {
    this.filedashboardService.getUploadFileStatus(params).subscribe((res) => {
      this.uploadFilesList = res?.updateStatusResponses.map(obj => ({ ...obj, isStatusRecord: true }));
    });
  }

  async loadFileDashboard() {
    this.uploadFilesList=[];
    this.patientdate=[];
    this.userInfo = this.authService.getItem('userDetails');
    //To validate user role for recall button
    this.recallUserRole(this.userInfo.userRole);
    this.dashboardObject = {
      "linkedFacilityId": this.userInfo.userFacilityId,
      "searchText": this.searchText ? this.searchText : '',
      "targetFacilityIds": [],
      "surgeonIds": [],
      "applicationIds": [],
      "statuses": [],
      "fromUploadDate": null,
      "toUploadDate": null,
      "page": 0,
      "limit": this.pagelimit,
      "column": constantStrings.createdDate
    }
    this.initialFlag = true;
    await new Promise((resolve)=>{
      this.sharedState.ssmIdleTimeData$.pipe(retry(1)).subscribe(response => {
        if (response && response.ssmKey == environment.ssmParams.RECALLTIME) {
          this.recallTime = +response.ssmVal;
          resolve(this.recallTime);
        }
      });
    })
    this.postFileDashboard(this.dashboardObject);
  }

  filterAppliedFlag() {
    if (this.selectedFilters) {
      if (this.selectedFilters.application.length > 0 || this.selectedFilters.site.length > 0 || this.selectedFilters.startDate || this.selectedFilters.endDate || this.selectedFilters.status.length > 0 || this.selectedFilters.surgeon.length > 0) {
        this.filedashboardService.isFilterApplied = true;
      } else {
        this.filedashboardService.isFilterApplied = false;
      }
    }
    else {
      this.filedashboardService.isFilterApplied = false;
    }
  }

  callMissingTagSuccess(event) {
    this.isUpdateSuccess = false;
    if(event===false)
    this.loadFileDashboard();
  }

  onFileRefersh(){
    this.loadFileDashboard();
  }

}